'use client'

import _ from 'lodash'
import {useCallback, useEffect, useRef} from 'react'
import {usePathname} from 'next/navigation'
import {type ThemeSwitcherFragment} from '~/cms/components/DatoThemeSwitcher/_generated/fragments.graphql'
import {type DisclaimerFragment} from '~/cms/components/SingleLineStructuredText/_generated/fragment.graphql'
import {grid, gridItem} from '~/design-system/foundations'
import {HgThemeSelector} from '~/design-system/hg/components'
import {themeToClassName, type Theme} from '~/design-system/hg/tokens/colors'
import {cn} from '~/design-system/utils'
import {usePrefersReducedMotion} from '~/hooks/useMediaQuery'
import {useDisclaimerContext} from '../DisclaimerProvider'
import DisclaimerStructuredText from './DisclaimerStructuredText'

export const MAIN_CONTENT_ID = 'content-without-disclaimers'
export const DISCLAIMER_SECTION_ID = 'disclaimer-section'

type DisclaimersProps = {
  theme: ThemeSwitcherFragment | undefined | null
  defaultDisclaimer?: DisclaimerFragment | null
  partnerDisclaimer?: DisclaimerFragment | null
}

const Disclaimers = ({
  theme,
  defaultDisclaimer,
  partnerDisclaimer,
}: DisclaimersProps) => {
  const disclaimerRef = useRef<HTMLDivElement | null>(null)
  const {disclaimers} = useDisclaimerContext()
  const shouldDisableParallax = usePrefersReducedMotion()
  const pathname = usePathname()
  const showPartnerDisclaimer = pathname.includes('/partner')

  const hideYOverscroll = () => {
    document.getElementsByTagName('html')[0].style.overscrollBehaviorY = 'none'
    document.getElementsByTagName('body')[0].style.overscrollBehaviorY = 'none'
  }

  const showYOverscroll = () => {
    document.getElementsByTagName('html')[0].style.overscrollBehaviorY = 'unset'
    document.getElementsByTagName('body')[0].style.overscrollBehaviorY = 'unset'
  }

  const themeName = theme ? (theme.name as Theme) : 'darkPurple'

  const isViewportSmallerThanDisclaimer = () =>
    window.innerHeight < (disclaimerRef.current?.offsetHeight || 0)

  const handleViewportSize = useCallback(() => {
    if (isViewportSmallerThanDisclaimer()) {
      // Reset bottom style in case user resized window
      disclaimerRef.current?.style.setProperty('bottom', '')
      disclaimerRef.current?.style.setProperty('top', '0')
    } else {
      // Reset top style in case user resized window
      disclaimerRef.current?.style.setProperty('top', '')
      disclaimerRef.current?.style.setProperty('bottom', '0')
    }
  }, [])

  const handleScroll = useCallback(() => {
    const mainElement = document.getElementById(MAIN_CONTENT_ID)
    if (!mainElement || !disclaimerRef.current) return

    handleViewportSize()

    if (
      window.scrollY > mainElement.offsetTop + mainElement.offsetHeight ||
      // don't want fixed footer to show up behind the top overscroll
      window.scrollY <= 0 ||
      shouldDisableParallax
    ) {
      showYOverscroll()
      disclaimerRef.current?.style.setProperty('position', 'static')
      mainElement.style.marginBottom = '0px'
    } else {
      // Need to add extra margin to allow scrolling/ account for disclaimer height
      // Take extra pixel off to prevent seeing body on Safari iOS
      mainElement.style.marginBottom = `${
        disclaimerRef.current?.offsetHeight - 1 || 0
      }px`
      disclaimerRef.current?.style.setProperty('position', 'fixed')
      if (!isViewportSmallerThanDisclaimer()) {
        // In the case of a shorter fixed disclaimer section, don't allow overscroll/ background
        // to show
        hideYOverscroll()
      }
    }
  }, [handleViewportSize, shouldDisableParallax])

  useEffect(() => {
    const debouncedHandleViewportSize = _.debounce(handleViewportSize, 100)
    window.addEventListener('resize', debouncedHandleViewportSize)

    return () => {
      debouncedHandleViewportSize.cancel()
      window.removeEventListener('resize', debouncedHandleViewportSize)
    }
  }, [handleViewportSize])

  useEffect(() => {
    const throttledHandleScroll = _.throttle(handleScroll, 17, {leading: true}) // approximately 60fps

    window.addEventListener('scroll', throttledHandleScroll)

    return () => {
      throttledHandleScroll.cancel()
      window.removeEventListener('scroll', throttledHandleScroll)
      // Make sure that overscroll behavior doesn't persist on other pages
      showYOverscroll()
    }
  }, [handleScroll])

  return (
    <HgThemeSelector theme={themeName}>
      <section
        id="disclaimer-section"
        ref={disclaimerRef}
        className={cn(themeToClassName[themeName], 'bg-background-default')}
        style={{zIndex: -1, left: 0, right: 0}}
      >
        <div
          className={grid({
            className: 'mx-auto pb-s12 text-text-default arcadia-body-5',
          })}
        >
          <div
            className={gridItem({
              size: 'max',
              className:
                'left-0 mt-s12 grid w-full gap-s9 border-t border-border-default bg-background-default pt-s4 text-text-default arcadia-body-5 aria-[hidden]:hidden md:grid-cols-12 md:gap-0',
            })}
            // data-nosnippet prevents google from generating content with this element
            data-nosnippet
          >
            <h2 className="md:col-span-4">Disclaimers and footnotes</h2>
            <ol
              id="disclaimers"
              className="list-outside list-decimal space-y-20 md:col-span-8"
            >
              {defaultDisclaimer && (
                <DisclaimerStructuredText data={defaultDisclaimer.text} />
              )}
              {showPartnerDisclaimer && partnerDisclaimer && (
                <DisclaimerStructuredText data={partnerDisclaimer.text} />
              )}
              {Object.values(disclaimers).length > 0 &&
                Object.values(disclaimers).map(disclaimer => (
                  <li
                    key={disclaimer.id}
                    id={`footnote-${disclaimer.kebabLabel}`}
                    className="scroll-mt-[var(--navbar-height)] space-y-20"
                  >
                    <DisclaimerStructuredText data={disclaimer.text} />
                  </li>
                ))}
            </ol>
          </div>
        </div>
      </section>
    </HgThemeSelector>
  )
}

export default Disclaimers
