// copied from https://github.com/MercuryTechnologies/mercury-web/blob/src/pages/Treasury/Onboarding/utils.tsx
import {BPSToPercent} from '../utils'
import BigNumber from 'bignumber.js'
import * as R from 'ramda'
import isChromatic from 'chromatic'
import {Day} from '~/utils/Calendar'
import {
  type TreasuryFeeTier,
  type GeneratedCusip,
  type TreasuryOnboardingData,
  type PortfolioSelectionUIData,
  type MULSX,
  type VUSXX,
} from './types'
import {generatedPortfolioData} from './generated'
import {type TCusip} from '~/mercuryWebCompat/api/generated/types/Cusip'

// Chromatic will render a diff every time the yield changes, so to prevent this
// we make fixed values for Chromatic builds.
const chromatic = isChromatic()

export const portfolioData: Record<GeneratedCusip, TreasuryOnboardingData> =
  chromatic
    ? {
        // Morgan Stanley MULSX
        '617455696': {
          fundSize: `$10.0B`,
          maturity: `50 days`,
          netYieldPct: 1.305,
          grossYieldPct: 1.61,
          mercuryFeePct: 0.3,
          composition: [
            ['Commercial Paper', 30],
            ['Certificates of Deposit', 25],
            ['Repurchase Agreements', 15],
            ['Corporate Notes', 10],
            ['Time Deposits', 20],
          ],
          updatedAt: new Day(2022, 1, 1),
        },
        // Vanguard VUSXX
        '921932109': {
          fundSize: `$35.0B`,
          maturity: `35 days`,
          netYieldPct: 0.711,
          grossYieldPct: 1.06,
          mercuryFeePct: 0.316,
          composition: [
            ['US Treasury Bills', 89.1],
            ['US Govt. Obligations', 10.9],
          ],
          updatedAt: new Day(2022, 1, 1),
        },
      }
    : generatedPortfolioData

export const launch2020VanguardCUSIP: VUSXX = '921932109'
export const launch2020MorganStanleyCUSIP: MULSX = '617455696'
export const CUSIP_VUSXX_NUMERIC = 921932109
export const CUSIP_MULSX_NUMERIC = 617455696

const baseTreasuryFee = 0.6

// a customer qualifies for lower fees than baseFee by having total deposits at or above the tier minimum
const feeStructure: TreasuryFeeTier[] = [
  {min: 20_000_000, fee: 0.15},
  {min: 10_000_000, max: 20_000_000, fee: 0.25},
  {min: 5_000_000, max: 10_000_000, fee: 0.35},
  {min: 2_000_000, max: 5_000_000, fee: 0.45},
  {min: 0, max: 2_000_000, fee: baseTreasuryFee},
]

/**
 * Mercury needs to charge a fee based off tiered fee cap
 * If the EFFR fee percentage is very low it will be less than the fee caps.
 * So we need to take the lower of those 2
 */
// MULSX, but this is generated off a percentage of EFFR
const {mercuryFeePct} = portfolioData[617455696]

// show the lowest fee tier when user is not logged in, this requires footnotes for user transparency
export const lowestTreasuryFeeDepositsDollars = feeStructure[0].min
// show the highest fee tier when user is logged in and we somehow don't have deposit information
// const depositsForHighestTreasuryFee = feeStructure[feeStructure.length - 1].min

// the minimum fee can be reached by the fed targeting an extremely low EFFR
// TODO: Check if we want the minimum fee to be sourced
const minimumFee = 0.05

export const calculateDefaultTreasuryFeeTier = (totalDeposits?: number) => {
  if (!totalDeposits) {
    return baseTreasuryFee
  }

  return (
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    R.find(feeTier => totalDeposits >= feeTier.min, feeStructure)?.fee ||
    baseTreasuryFee
  )
}

export const calculateTreasuryFee = (
  totalDeposits?: number,
  customFeeBPS?: number
) => {
  if (customFeeBPS !== undefined) {
    return BPSToPercent(customFeeBPS)
  } else {
    const treasuryFeeTier = calculateDefaultTreasuryFeeTier(totalDeposits)

    return Math.max(minimumFee, Math.min(treasuryFeeTier, mercuryFeePct))
  }
}

export const calculateTreasuryFeeFromTier = (
  feeTier?: number,
  customFeeBPS?: number
) => {
  if (customFeeBPS !== undefined) {
    return BPSToPercent(customFeeBPS)
  } else {
    const treasuryFeeTier =
      feeTier !== undefined ? BPSToPercent(feeTier) : baseTreasuryFee

    return Math.max(minimumFee, Math.min(treasuryFeeTier, mercuryFeePct))
  }
}

// the treasury rate and fee we show is often truncated after 2 decimals. Using bignumber because of unfortunate js rounding.
export const truncateFromBigNumber = (input: number, numOfDecimals?: number) =>
  new BigNumber(input).toFixed(numOfDecimals ?? 2, 1)

export const createTruncatedTreasuryYield = (
  totalDeposits: number,
  numOfDecimals?: number
) => {
  // kinda lame way to reuse the data, but at least we won't forget to update this elsewhere now
  const expectedGrossYieldPercent =
    generatedPortfolioData[CUSIP_MULSX_NUMERIC].grossYieldPct

  return truncateFromBigNumber(
    expectedGrossYieldPercent - calculateTreasuryFee(totalDeposits),
    numOfDecimals
  )
}

export const createTruncatedTreasuryYieldFromTier = (
  feeTier: number,
  numOfDecimals?: number
) => {
  // kinda lame way to reuse the data, but at least we won't forget to update this elsewhere now
  const expectedGrossYieldPercent =
    generatedPortfolioData[CUSIP_MULSX_NUMERIC].grossYieldPct

  return truncateFromBigNumber(
    expectedGrossYieldPercent - calculateTreasuryFeeFromTier(feeTier),
    numOfDecimals
  )
}

export const truncatedHighestTreasuryYield = createTruncatedTreasuryYield(
  lowestTreasuryFeeDepositsDollars,
  2
)

// the fund data / yields / etc should be updated often, someday automatically would be nice
// doc describing how to get/update the data we show here:
// https://www.notion.so/mercurytechnologies/1-8-3-1-Funds-data-in-the-dash-3149ba95e2c640778986c0c11f53e94c
export const portfolioSelectionUIDataForCUSIP: Record<
  TCusip,
  PortfolioSelectionUIData
> = {
  // test: Google
  '38259P508': {
    funTitle: `Google`,
    funSubtitle: `Tech giant`,
    tags: [`Technology`],
    fullTitle: `Google Stock`,
    description: `Google is a tech giant in Silicon Valley.`,
    ticker: `GOOG`,
    fundSize: ``,
    maturity: ``,
    netYieldDays: 30,
    netYieldPct: 0,
    grossYieldPct: 0,
    mercuryFeePct: 0,
    composition: [['', 0]],
    updatedAt: new Day(2020, 1, 1),
    prospectusUrl: 'https://google.com',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // test: Apple
  '037833100': {
    funTitle: `Apple`,
    funSubtitle: `Tech giant`,
    tags: [`Technology`],
    fullTitle: `Apple Stock`,
    description: `Apple is a tech giant in Silicon Valley.`,
    ticker: `AAPL`,
    fundSize: ``,
    maturity: ``,
    netYieldDays: 30,
    netYieldPct: 0,
    grossYieldPct: 0,
    mercuryFeePct: 0,
    composition: [['', 0]],
    updatedAt: new Day(2020, 1, 1),
    prospectusUrl: 'https://apple.com',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // test: Grayscale Bitcoin Trust (GTBC)
  '389637109': {
    funTitle: `GTBC`,
    funSubtitle: `Crypto`,
    tags: [`Crypto`],
    fullTitle: `Grayscale Bitcoin Trust`,
    description: `Grayscale Bitcoin Trust (BTC) is an exchange traded fund launched and managed by Grayscale Investments, LLC. The fund invests in Bitcoins. It invests through derivatives such as futures, swaps, and other CFTC-regulated derivatives that reference digital currencies. The fund seeks to track the performance of the TradeBlock XBX Index.`,
    ticker: `GTBC`,
    fundSize: ``,
    maturity: ``,
    netYieldDays: 30,

    netYieldPct: 0,
    grossYieldPct: 0,
    mercuryFeePct: 0,

    composition: [['', 0]],
    updatedAt: new Day(2020, 1, 1),
    prospectusUrl: 'https://grayscale.co/bitcoin-trust/',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // test: Bitwise 10 Crypto Index Fund (BITW)
  '091749101': {
    funTitle: `BITW`,
    funSubtitle: `Crypto`,
    tags: [`Crypto`],
    fullTitle: `Bitwise 10 Crypto Index Fund`,
    description: `A secure way to get diversified exposure to bitcoin and leading cryptocurrencies. It seeks to track an Index comprised of the 10 most highly valued cryptocurrencies, screened & monitored for certain risks, weighted by market capitalization, and rebalanced monthly.`,
    ticker: `BITW`,
    fundSize: ``,
    maturity: ``,
    netYieldDays: 30,

    netYieldPct: 0,
    grossYieldPct: 0,
    mercuryFeePct: 0,

    composition: [['', 0]],
    updatedAt: new Day(2020, 1, 1),
    prospectusUrl: 'https://www.bitwiseinvestments.com/funds/Bitwise-10',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // Vanguard VMFXX
  '922906300': {
    funTitle: `Runway vault`,
    funSubtitle: `Lower risk`,
    tags: [`US Government securities`, `Cash equivalents`],
    fullTitle: `Vanguard Federal Money Market Fund Investor Shares`,
    description: `VMFXX fund is one of the most conservative investment options offered by Vanguard. The fund seeks to provide current income and preserve shareholders’ principal investment by maintaining a share price of $1.`,
    ticker: `VMFXX`,
    fundSize: `$199.6B`,
    maturity: `57 days`,
    netYieldDays: 7,

    grossYieldPct: 0.05,
    mercuryFeePct: 0.05,
    netYieldPct: 0,

    composition: [
      ['US Treasury Bills', 74.7],
      ['US Govt. Obligations', 20.3],
      ['Repurchase Agreements', 5.0],
    ],
    updatedAt: new Day(2020, 10, 6),
    prospectusUrl:
      'https://investor.vanguard.com/mutual-funds/profile/overview/vmfxx',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // Vanguard VUSXX - "Better" version of VMFXX we want to use instead
  '921932109': {
    funTitle: `Vanguard Treasury Money Market Fund`,
    funSubtitle: `99.5% US Government securities`,
    tags: [`US Government securities`, `Cash equivalents`],
    fullTitle: `Vanguard Treasury Money Market Fund`,
    description: `One of Vanguard’s ultra conservative U.S. federal money market funds, with at least 80% of those being U.S. Treasury bills and repurchase agreements fully collateralized by U.S. Treasury securities.`,
    ticker: `VUSXX`,
    fundSize: portfolioData['921932109'].fundSize,
    maturity: portfolioData['921932109'].maturity,
    netYieldDays: 7,

    // NB: also change treasuryStrategyUIData via the spreadsheet linked there if you change this!
    grossYieldPct: portfolioData['921932109'].grossYieldPct,
    mercuryFeePct: portfolioData['921932109'].mercuryFeePct,
    netYieldPct: portfolioData['921932109'].netYieldPct,

    composition: portfolioData['921932109'].composition,
    updatedAt: portfolioData['921932109'].updatedAt,
    prospectusUrl:
      'https://investor.vanguard.com/mutual-funds/profile/overview/vusxx',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // Morgan Stanley MULSX
  '617455696': {
    funTitle: `Morgan Stanley Ultra-Short Income Portfolio`,
    funSubtitle: `Commercial paper, CDs, repos and corporate notes`,
    tags: [`Corporate bonds`, `Cash equivalents`],
    fullTitle: `Morgan Stanley Ultra-Short Income Portfolio`,
    description: `Ultra-short bond fund from Morgan Stanley comprising mostly cash equivalents, with other ultra-short term assets including corporate bonds and bank loans.`,
    ticker: `MULSX`,
    fundSize: portfolioData['617455696'].fundSize,
    maturity: portfolioData['617455696'].maturity,
    netYieldDays: 7,

    // NB: also change treasuryStrategyUIData via the spreadsheet linked there if you change this!
    grossYieldPct: portfolioData['617455696'].grossYieldPct,
    mercuryFeePct: portfolioData['617455696'].mercuryFeePct,
    netYieldPct: portfolioData['617455696'].netYieldPct,

    composition: portfolioData['617455696'].composition,
    updatedAt: portfolioData['617455696'].updatedAt,
    prospectusUrl:
      'https://www.morganstanley.com/im/en-us/institutional-investor/product-and-performance/mutual-funds/taxable-fixed-income/ultra-short-income-portfolio.shareClass.IR.html',
    riskRating: 'AAAf/S1',
    riskRatingTooltip:
      'Based on the Fitch Rating scale. AAAf is the highest rating for underlying credit quality. S1 indicates very low sensitivity to market risk.',
  },
  // test: Citigroup Inc
  '172967424': {
    funTitle: `Citigroup`,
    funSubtitle: `Multinational investment bank`,
    tags: [`Finance`],
    fullTitle: `Citigroup Stock`,
    description: `Citigroup is an American multinational investment bank and financial services company.`,
    ticker: `C`,
    fundSize: ``,
    maturity: ``,
    netYieldDays: 30,
    netYieldPct: 0,
    grossYieldPct: 0,
    mercuryFeePct: 0,
    composition: [['', 0]],
    updatedAt: new Day(2020, 1, 1),
    prospectusUrl: 'https://www.citigroup.com/citi/',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // test: Goldman Sachs
  '38141G104': {
    funTitle: `Goldman Sachs`,
    funSubtitle: `Multinational investment bank`,
    tags: [`Finance`],
    fullTitle: `Goldman Sachs Stock`,
    description: `Goldman Sachs is an American multinational investment bank and financial services company.`,
    ticker: `GS`,
    fundSize: ``,
    maturity: ``,
    netYieldDays: 30,
    netYieldPct: 0,
    grossYieldPct: 0,
    mercuryFeePct: 0,
    composition: [['', 0]],
    updatedAt: new Day(2020, 1, 1),
    prospectusUrl: 'https://www.goldmansachs.com/',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // test: Google
  '02079K107': {
    funTitle: `Google`,
    funSubtitle: `Tech giant`,
    tags: [`Technology`],
    fullTitle: `Google Stock`,
    description: `Google is a tech giant in Silicon Valley.`,
    ticker: `GOOG`,
    fundSize: ``,
    maturity: ``,
    netYieldDays: 30,
    netYieldPct: 0,
    grossYieldPct: 0,
    mercuryFeePct: 0,
    composition: [['', 0]],
    updatedAt: new Day(2020, 1, 1),
    prospectusUrl: 'https://google.com',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
  // test: Google
  '02079K305': {
    funTitle: `Google`,
    funSubtitle: `Tech giant`,
    tags: [`Technology`],
    fullTitle: `Google Stock`,
    description: `Google is a tech giant in Silicon Valley.`,
    ticker: `GOOGL`,
    fundSize: ``,
    maturity: ``,
    netYieldDays: 30,
    netYieldPct: 0,
    grossYieldPct: 0,
    mercuryFeePct: 0,
    composition: [['', 0]],
    updatedAt: new Day(2020, 1, 1),
    prospectusUrl: 'https://google.com',
    riskRating: undefined,
    riskRatingTooltip: undefined,
  },
}

export const treasuryStrategyRatesUpdatedOn: Day = Day.latestOf(
  portfolioSelectionUIDataForCUSIP[launch2020VanguardCUSIP].updatedAt,
  portfolioSelectionUIDataForCUSIP[launch2020MorganStanleyCUSIP].updatedAt
)
