/** Calls the given callback iff `maybe` is not undefined/null */
export const safely = <T, U>(
  maybe: T | undefined | null,
  then: (t: NonNullable<T>) => U
): U | undefined => {
  if (maybe !== undefined && maybe !== null) {
    return then(maybe as NonNullable<T>)
  } else {
    return undefined
  }
}

/** Calls the given callback iff `maybe` is not undefined/null, otherwise return `orElse` */
export const safelyOr = <T, U>(
  maybe: T | undefined | null,
  then: (t: T) => U,
  orElse: U
): U => {
  if (maybe !== undefined && maybe !== null) {
    return then(maybe)
  } else {
    return orElse
  }
}
