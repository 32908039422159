'use client'

import {Analytics} from '~/utils/Analytics'
// eslint-disable-next-line no-restricted-imports
import {HgTextLink, type HgTextLinkProps} from '~/design-system/hg/components'
import {type ClickEvent} from '~/utils/Analytics/types'

type FEMLinkProps = HgTextLinkProps & {
  analyticsEvent?: ClickEvent
}

const FEMLink = ({analyticsEvent, onClick, href, ...props}: FEMLinkProps) => (
  <HgTextLink
    href={href}
    onClick={event => {
      const linkEl = event.target as HTMLElement
      analyticsEvent &&
        Analytics.trackClick(
          Analytics.mergeProperties(analyticsEvent, {
            text: linkEl.innerText,
            linkedPath: href,
          })
        )
      onClick?.(event)
    }}
    {...props}
  />
)

export default FEMLink
