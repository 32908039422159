import {isLink} from 'datocms-structured-text-utils'
import {
  StructuredText,
  renderMarkRule,
  renderNodeRule,
} from 'react-datocms/structured-text'
import {DatoConstant} from '~/cms/components/Constants/DatoConstant'
import {type SingleLineStructuredTextFragment} from '~/cms/components/SingleLineStructuredText/_generated/fragment.graphql'
import FEMLink from '~/components/FEMLink'
import IO from '~/components/IO'

type DisclaimerStructuredTextProps = {
  data: SingleLineStructuredTextFragment['text']
}

export default function DisclaimerStructuredText({
  data,
}: DisclaimerStructuredTextProps) {
  return (
    <StructuredText
      customNodeRules={[
        renderNodeRule(isLink, ({children, node, key}) => {
          return (
            <FEMLink
              key={key}
              href={node.url}
              className="arcadia-body-5"
              variant="disclaimer"
            >
              {children}
            </FEMLink>
          )
        }),
      ]}
      customMarkRules={[
        renderMarkRule('io-font', ({key}) => {
          return <IO key={key} />
        }),
      ]}
      renderLinkToRecord={({record}) => {
        switch (record.__typename) {
          case 'ConstantRecord':
            return <DatoConstant data={record} />
          default:
            return null
        }
      }}
      data={data}
    />
  )
}
