import * as DateFns from 'date-fns'
import {isSameDay} from 'date-fns'
import * as R from 'ramda'
import {greaterThan, greaterThanOrEqual} from '~/utils/Comparable'
import * as C from '~/utils/Comparable'
import type {DayOfWeek, Weekday} from '~/utils/Day/types'
import type * as Equatable from '~/utils/Equatable'
import {recordKeys} from '../recordKeys'
import {unreachableCase} from '~/design-system/utils'
import {type ToJSONValue} from '~/mercuryWebCompat/api/adapters/base'
import {newDate} from '../date'
import {safely} from '../safely'
import {notifyBugsnag} from '../Bugsnag/notify'

/**
 * Formats a date conditionally, based on whether it is "this year" or not, in the user's timezone.
 * @param {Date} date
 * @param {string} otherYearFormat Format to use on a date other than dates "this year"
 * @param {string} thisYearFormat Format to use on a date "this year"
 * @returns {string} Formatted date string
 */
export const formatDateByAge = (
  date: Date,
  otherYearFormat: string = 'MMM yyyy',
  thisYearFormat: string = 'MMM d'
) => {
  const today = newDate()

  if (date.getFullYear() === today.getFullYear()) {
    return DateFns.format(date, thisYearFormat)
  } else {
    return DateFns.format(date, otherYearFormat)
  }
}

export const isLeapYear = (year: number) =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

export const isToday = (userDate: string) => {
  if (userDate.toLowerCase() === 'today') {
    return true
  }

  const date = new Date(userDate)
  const today = newDate()

  return isSameDay(date, today)
}

export class Month implements C.Comparable<Month>, Equatable.Equatable {
  year: number
  month: number
  constructor(year: number, month: number) {
    // this.month is 1 indexed, vs Javascript's Date's 0-indexed months
    if (month < 1 || month > 12) {
      throw new Error(`Invalid month: ${month}`)
    }
    this.year = year
    this.month = month
  }

  public toMonthYear() {
    return DateFns.format(this.toFirstDay().toDate(), 'MMMM yyyy')
  }

  public toComponents() {
    return [this.year, this.month]
  }

  public compare(month: Month): C.CompareResult {
    return C.compareArrays(this.toComponents(), month.toComponents())
  }

  equals = (other: any): boolean => {
    if (other && other.constructor === Month) {
      return this.compare(other) === C.CompareResult.EQ
    } else {
      return false
    }
  }

  // This is a count of hypothetical gregorian months extrapolated back to
  // 0 = January in 1BCE
  public toCEIndex() {
    return this.year * 12 + this.month - 1
  }

  public static fromCEIndex(ceIndex: number) {
    return new Month(Math.floor(ceIndex / 12), Math.floor(ceIndex % 12) + 1)
  }

  public static rangeInclusive(start: Month, end: Month): Month[] {
    return R.range(start.toCEIndex(), end.toCEIndex() + 1).map(ord =>
      Month.fromCEIndex(ord)
    )
  }

  /*
   * [start, end)
   */
  public static rangeInEx(start: Month, end: Month): Month[] {
    return R.range(start.toCEIndex(), end.toCEIndex()).map(ord =>
      Month.fromCEIndex(ord)
    )
  }

  public static monthsForYear(year: number): Month[] {
    return R.range(1, 13).map(month => new Month(year, month))
  }

  public static currentMonth(): Month {
    return Month.fromDate(newDate())
  }

  public static currentMonthUTC(): Month {
    return Month.fromDateUTC(newDate())
  }

  public static fromDate(date: Date): Month {
    return new Month(date.getFullYear(), date.getMonth() + 1)
  }

  public static fromDateUTC(date: Date): Month {
    return new Month(date.getUTCFullYear(), date.getUTCMonth() + 1)
  }

  public addMonths(months: number): Month {
    return Month.fromCEIndex(this.toCEIndex() + months)
  }

  public subMonths(months: number): Month {
    return this.addMonths(months * -1)
  }

  public toFirstDay(): Day {
    return new Day(this.year, this.month, 1)
  }

  public toLastDay(): Day {
    const lastDate = DateFns.lastDayOfMonth(this.toFirstDay().toDate())
    return Day.fromDate(lastDate)
  }

  public allDays(): Day[] {
    const first = this.toFirstDay()
    return R.range(0, this.numberOfDays()).map(i => first.addDays(i))
  }

  public numberOfDays(): number {
    const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    return this.month === 2
      ? isLeapYear(this.year)
        ? 29
        : 28
      : days[this.month - 1]
  }

  // used by JS to facilitate the comparison operators i.e. `<` `>=`, but notably not for `==` nor `===`
  valueOf(): number {
    return this.toCEIndex()
  }

  toJSONValue = (): string => {
    return this.toServerFormat()
  }

  toServerFormat = (): string => {
    return DateFns.format(this.toFirstDay().toDate(), serverMonthFormat)
  }

  static fromServer(s: string) {
    return Month.fromDate(DateFns.parse(s, serverMonthFormat, newDate()))
  }
}

// The format the server sends and expects to receive for the backend type `Day`
export const serverDayFormat = 'yyyy-MM-dd'

// The format the server sends and expects to receive for the backend type `Month`
// (it essentially uses a `Day` with the day set to `01`)
export const serverMonthFormat = serverDayFormat

export const clientDayFormat = 'MM/dd/yyyy'
const urlFormat = 'MM-dd-yyyy' // (Since slashes are escaped in URLs)

export const humanDays: Record<DayOfWeek, string> = {
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
}

export const daysOfWeek: DayOfWeek[] = recordKeys(humanDays)
export const allWeekdays: Weekday[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
]

export const humanWeekDays = allWeekdays.reduce(
  (acc, day) => ({...acc, [day]: humanDays[day]}),
  {}
)

// prettier-ignore
type TFancyClientFormatOptions = {
  // Choose whether the date will include the year or not.
  // default false (year included)
  excludeYear?: boolean | 'byAge'

  // Choose to have the abbreviation (Yesterday, Today, or Tomorrow) and/or the date
  // default 'abbr'
  style?:                 // abbreviation exists  | no abbreviation exists
                          // ----------------------------------------------
    | 'abbr-and-date'     // `Tomorrow, May 1`    | `May 2`
    | 'abbr'              // `Tomorrow`           | `May 2`
    | 'date'              // `May 1`              | `May 2`
    | 'abbr-or-dayOfWeek' // `Tomorrow`           | `Tuesday`

  // e.g. `today` instead of `Today`, default false
  lowercaseAbbreviation?: boolean
}

export class Day implements ToJSONValue, C.Comparable<Day>, Equatable.Equatable {
  day: number
  month: number
  year: number

  constructor(year: number, month: number, day: number) {
    // Months are 1 indexed, vs Javascript's 0-indexed months
    if (month < 1 || month > 12) {
      throw new Error(`Invalid month: ${month}`)
    }
    this.year = year
    this.month = month
    this.day = day
  }

  /**
   * Returns a Date for this Day with the time set to midnight in the local timezone,
   * which means the numerical year/month/day in the returned Date will always match what this Day has.
   * e.g.:
   * Seattle:
   * - new Day(2020, 12, 1).toDate() === Tue Dec 01 2020 00:00:00 GMT-0800 (Pacific Standard Time)
   * Copenhagen:
   * - new Day(2020, 12, 1).toDate() === Tue Dec 01 2020 00:00:00 GMT+0100 (Central European Standard Time)
   */
  toDate = (): Date => {
    return new Date(this.year, this.month - 1, this.day)
  }

  /**
   * Returns a Date for this Day with the time set to midnight UTC,
   * which means the numerical year/month/day in the returned Date will NOT always match what this Day has.
   * e.g.:
   * Seattle:
   * - new Day(2020, 12, 1).toUTCDate() === Mon Nov 30 2020 16:00:00 GMT-0800 (Pacific Standard Time)
   * Copenhagen:
   * - new Day(2020, 12, 1).toUTCDate() === Tue Dec 01 2020 01:00:00 GMT+0100 (Central European Standard Time)
   */
  toUTCDate = (): Date => {
    return new Date(Date.UTC(this.year, this.month - 1, this.day))
  }

  addDays = (n: number): Day => {
    return Day.fromDate(new Date(this.year, this.month - 1, this.day + n))
  }

  addMonths = (n: number): Day => {
    // if you do (May 31).addMonths(-1), we want to return the last day of April,
    // not what Date would do by default which would be (April 31) === (May 1), since
    // April only has 30 days.

    // using day 1 of the month to avoid overflowing months, this is fixed below
    let newDay = Day.fromDate(new Date(this.year, this.month - 1 + n, 1))

    if (newDay.toMonth().numberOfDays() < this.day) {
      // e.g. (March 31).addMonths(-1), Feb has 28/29 days. Return last day of month
      newDay = newDay.toMonth().toLastDay()
    } else {
      newDay.day = this.day
    }

    return newDay
  }

  addYears = (n: number): Day => {
    return Day.fromDate(new Date(this.year + n, this.month - 1, this.day))
  }

  subDays = (n: number): Day => this.addDays(-1 * n)
  subMonths = (n: number): Day => this.addMonths(-1 * n)
  subYears = (n: number): Day => this.addYears(-1 * n)

  difference = (d: Day): number =>
    DateFns.differenceInDays(this.toDate(), d.toDate())

  /**
   * Returns a Day for this Date, after interpreting the Date from the UTC time zone's perspective.
   * which means the numerical year/month/day in the returned Day will always match those values in a UTC date string.
   *
   * This is the proper choice for determining what Day a server-sent timestamp is on from the server's (UTC)
   * perspective.
   *
   * e.g.:
   * fromDateUTC(new Date('2020-12-01T01:00:00Z')) === new Day(2020,12,01)
   * (the `Z` at the end of the Date string means UTC, so the day in the Date string will always match the Day)
   */
  static fromDateUTC = (date: Date): Day => {
    return new Day(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate())
  }

  /**
   * Returns a Day for this Date, after interpreting the Date from the user's local time zone's perspective.
   * which means the numerical year/month/day in the returned Day will NOT always match those values in a UTC date
   * string.
   *
   * This is the proper choice for determining what Day a server-sent timestamp is on from the user's perspective.
   *
   * e.g.:
   * fromDateUTC(new Date('2020-12-01T01:00:00Z')) === new Day(2020,11,30) // (if the user is in [UTC-8], for example)
   * fromDateUTC(new Date('2020-12-01T01:00:00Z')) === new Day(2020,12,01) // (if the user is in [UTC+1], for example)
   */
  static fromDate = (date: Date): Day => {
    return new Day(date.getFullYear(), date.getMonth() + 1, date.getDate())
  }

  toJSONValue = (): string => {
    return this.toServerFormat()
  }

  toServerFormat = (): string => {
    return DateFns.format(this.toDate(), serverDayFormat)
  }

  toISOTime = (): string => {
    return this.toDate().toISOString()
  }

  toISOTimeUTC = (): string => {
    return this.toUTCDate().toISOString()
  }

  toClientFormat = (): string => {
    return DateFns.format(this.toDate(), clientDayFormat)
  }

  toURLFormat = (): string => {
    return DateFns.format(this.toDate(), urlFormat)
  }

  private readonly _toFancyClientFormatWithAbbreviator = (
    abbreviator: (day: Day) => string | undefined,
    opts?: TFancyClientFormatOptions
  ): string => {
    const {
      excludeYear = false,
      style = 'abbr',
      lowercaseAbbreviation = false,
    } = opts ?? {}

    const dateStr =
      excludeYear === 'byAge'
        ? formatDateByAge(this.toDate(), 'MMM d, yyyy', 'MMM d')
        : DateFns.format(this.toDate(), excludeYear ? 'MMM d' : 'MMM d, yyyy')

    if (style === 'date') {
      return dateStr
    }

    let abbrWord = abbreviator(this)
    if (lowercaseAbbreviation) {
      abbrWord = safely(abbrWord, w => w.toLowerCase())
    }

    switch (style) {
      case 'abbr-and-date':
        if (abbrWord === undefined) {
          return dateStr
        }
        return `${abbrWord}, ${dateStr}`
      case 'abbr':
        if (abbrWord === undefined) {
          return dateStr
        }
        return abbrWord
      case 'abbr-or-dayOfWeek':
        return abbrWord ?? this.dayOfWeekHuman()
      default:
        return unreachableCase(style)
    }
  }

  toFancyClientFormat = (opts?: TFancyClientFormatOptions): string => {
    return this._toFancyClientFormatWithAbbreviator(
      day =>
        day.equals(Day.today())
          ? `Today`
          : day.equals(Day.tomorrow())
            ? `Tomorrow`
            : day.equals(Day.yesterday())
              ? `Yesterday`
              : undefined,
      opts
    )
  }

  toFancyClientFormatUTC = (opts?: TFancyClientFormatOptions): string => {
    return this._toFancyClientFormatWithAbbreviator(
      day =>
        day.equals(Day.todayUTC())
          ? `Today`
          : day.equals(Day.tomorrowUTC())
            ? `Tomorrow`
            : day.equals(Day.yesterdayUTC())
              ? `Yesterday`
              : undefined,
      opts
    )
  }

  format = (formatString: string): string => {
    try {
      return DateFns.format(this.toDate(), formatString)
    } catch (err) {
      notifyBugsnag('Error formatting Day as a string', {
        caughtError: err,
        metadata: {
          day: this.day,
          month: this.month,
          year: this.year,
        },
      })
    }

    return ''
  }

  toMonth = (): Month => {
    return new Month(this.year, this.month)
  }

  compare = (day: Day): C.CompareResult => {
    return C.compareArrays(this.toComponents(), day.toComponents())
  }

  isAfter = (day: Day): boolean => {
    return greaterThan(this, day)
  }

  isAfterOrEqual = (day: Day): boolean => {
    return greaterThanOrEqual(this, day)
  }

  isBefore = (day: Day): boolean => {
    return greaterThan(day, this)
  }

  isBeforeOrEqual = (day: Day): boolean => {
    return greaterThanOrEqual(day, this)
  }

  /** Whether it's between the provided start and end (inclusive) */
  isBetween = (start: Day, end: Day): boolean => {
    return start.isBeforeOrEqual(this) && this.isBeforeOrEqual(end)
  }

  /** Saturday - Sunday */
  isWeekend = (): boolean => {
    const idx = this.dayOfWeekIndex()
    return idx === 0 || idx === 6
  }

  /** Monday - Friday */
  isWeekday = (): boolean => !this.isWeekend()

  // This method can be called by Ramda which will ignore the type required of `other`
  // so we check this manually at run-time. However, using `any` for the type declaration isn't useful for our own code.
  equals = (other: Day): boolean => {
    if (Day.isDay(other)) {
      return this.compare(other) === C.CompareResult.EQ
    } else {
      return false
    }
  }

  clone = (): Day => {
    return new Day(this.year, this.month, this.day)
  }

  toComponents() {
    return [this.year, this.month, this.day]
  }

  dayOfWeek(): DayOfWeek {
    return daysOfWeek[this.dayOfWeekIndex()]
  }

  // Sunday = 0, Monday = 1, ..., Saturday = 6
  dayOfWeekIndex(): number {
    return this.toDate().getDay()
  }

  dayOfWeekHuman(): string {
    return humanDays[this.dayOfWeek()]
  }

  startOfWeek(): Day {
    const dateObj = this.toDate()
    const dateDiff = this.day - this.dayOfWeekIndex()
    dateObj.setDate(dateDiff)
    return Day.fromDate(dateObj)
  }

  endOfWeek(): Day {
    const startOfWeekDayObj = this.startOfWeek().toDate()
    startOfWeekDayObj.setDate(startOfWeekDayObj.getDate() + 6)
    return Day.fromDate(startOfWeekDayObj)
  }

  /* Returns true if the Day has valid members. */
  areMembersNumeric(): boolean {
    return (
      !isNaN(this.day) &&
      !isNaN(this.month) &&
      !isNaN(this.year) &&
      this.day !== undefined &&
      this.month !== undefined &&
      this.year !== undefined
    )
  }

  static isDay = (obj: any): obj is Day => {
    return !!obj && obj instanceof Day
  }

  /** Day.fromClient('12/31/2020') */
  static fromClient(s: string): Day {
    return Day.fromDate(DateFns.parse(s, clientDayFormat, newDate()))
  }

  static maybeFromClient(s: string): Day | undefined {
    const parsed = DateFns.parse(s, clientDayFormat, newDate())
    if (isNaN(parsed.getDate())) {
      return undefined
    } else {
      return Day.fromDate(parsed)
    }
  }

  static fromClientThrows(s: string): Day {
    const maybeDay = this.fromClient(s)
    if (maybeDay) {
      return maybeDay
    } else {
      throw new Error('Invalid Day: ' + s)
    }
  }

  /** Day.fromURLFormat('12-31-2020') */
  static fromURLFormat(s: string): Day | undefined {
    const parsed = DateFns.parse(s, urlFormat, newDate())
    if (isNaN(parsed.getDate())) {
      return undefined
    } else {
      return Day.fromDate(parsed)
    }
  }

  static maybeFromServer(s: string): Day | undefined {
    const parsed = DateFns.parse(s, serverDayFormat, newDate())
    if (isNaN(parsed.getDate())) {
      return undefined
    } else {
      return Day.fromDate(parsed)
    }
  }

  /** Day.fromServer('2020-12-31') */
  static fromServer(s: string): Day {
    return Day.fromDate(DateFns.parse(s, serverDayFormat, newDate()))
  }

  /**
   * Returns a Day for this Date string, after interpreting the Date from the user's local time zone's perspective.
   * which means the numerical year/month/day in the returned Day will NOT always match those values in a UTC date
   * string.
   *
   * This is the proper choice for determining what Day a server-sent timestamp is on from the user's perspective.
   *
   * e.g.:
   * fromISOTime('2020-12-01T01:00:00Z') === new Day(2020,11,30) // (if the user is in [UTC-8], for example)
   * fromISOTime('2020-12-01T01:00:00Z') === new Day(2020,12,01) // (if the user is in [UTC+1], for example)
   */
  static fromISOTime(s: string) {
    return Day.fromDate(new Date(s))
  }

  /**
   * Returns a Day for this Date string, after interpreting the Date from the UTC time zone's perspective.
   * which means the numerical year/month/day in the returned Day will always match those values in a UTC date string.
   *
   * This is the proper choice for determining what Day a server-sent timestamp is on from the server's (UTC)
   * perspective.
   *
   * e.g.:
   * fromISOTimeUTC('2020-12-01T01:00:00Z') === new Day(2020,12,01)
   * (the `Z` at the end of the Date string means UTC, so the day in the Date string will always match the Day)
   */
  static fromISOTimeUTC(s: string) {
    return Day.fromDateUTC(new Date(s))
  }

  static today(): Day {
    return Day.fromDate(newDate())
  }

  static yesterday(): Day {
    return Day.fromDate(DateFns.subDays(newDate(), 1))
  }

  static tomorrow(): Day {
    return Day.fromDate(DateFns.addDays(newDate(), 1))
  }

  static theDayAfterTomorrow(): Day {
    return Day.tomorrow().nextDay()
  }

  // for checking birth date
  static eighteenYearsAgo(): Day {
    return Day.fromDate(DateFns.subYears(newDate(), 18))
  }

  /** when sorting stuff that might have an `undefined` `Day` property, this can be
   * helpful as a fallback to group `undefined` items together */
  static epoch(): Day {
    return Day.fromDateUTC(new Date('January 1, 1970'))
  }

  // Use the current year/month/date from the UTC time zone to construct a Day object
  // If it is 9pm -7 PST on the 2nd locally, it is actually the 3rd in UTC. The Day returned would be the 3rd.
  // If it is 3pm -7 PST on the 2nd locally, it is also the 2nd in UTC. The Day returned would be the 2nd.
  static todayUTC(): Day {
    return Day.fromDateUTC(newDate())
  }

  static tomorrowUTC(): Day {
    return Day.todayUTC().addDays(1)
  }

  static yesterdayUTC(): Day {
    return Day.todayUTC().subDays(1)
  }

  static compare(a: Day, b: Day): -1 | 0 | 1 {
    return C.compareArrays(a.toComponents(), b.toComponents())
  }

  static latestOf(a: Day, b: Day, ...additional: Day[]): Day {
    return C.max(a, b, ...additional)
  }

  nextDay(): Day {
    return Day.fromDate(DateFns.addDays(this.toDate(), 1))
  }

  /**
   * Get the previous occurrence of a day of the week, starting from `this`. Ties will return a Day a week in the past.
   *
   * ```
   * (X = Day on Friday).previous('thursday')   === (day before X)
   * (X = Day on Thursday).previous('thursday') === (one week before X)
   * ```
   */
  previous(dayOfWeek: DayOfWeek): Day {
    const thisIdx = daysOfWeek.indexOf(this.dayOfWeek())
    const thatIdx = daysOfWeek.indexOf(dayOfWeek)

    let offset = thisIdx + 7 - thatIdx
    if (offset > 7) {
      offset = offset % 7
    }
    return this.subDays(offset)
  }

  /**
   *  Get the next occurrence of a day of the week, starting from `this`. Ties will return a Day a week in the future.
   *
   * ```
   * (X = Day on Wednesday).next('thursday') === (day after X)
   * (X = Day on Thursday).next('thursday')  === (one week after X)
   * ```
   */
  next(dayOfWeek: DayOfWeek): Day {
    const thisIdx = daysOfWeek.indexOf(this.dayOfWeek())
    const thatIdx = daysOfWeek.indexOf(dayOfWeek)

    let offset = thatIdx + 7 - thisIdx
    if (offset > 7) {
      offset = offset % 7
    }
    return this.addDays(offset)
  }

  /**
   * Get the occurrence number for the day of the week.
   * E.g. if it’s the first Monday of the month, the function will return 1,
   * if it’s the fourth or fifth Monday of the month, the function will return -1.
   */
  nthOccurrenceOfNDayInCurrentMonth(): number {
    let count: number = 0
    let newMonth = this.subDays(7)

    while (newMonth.toMonth().month === this.toMonth().month) {
      count = count + 1
      newMonth = newMonth.subDays(7)
    }
    return count > 2 ? -1 : count + 1
  }

  // used by JS to facilitate the comparison operators i.e. `<` `>=`, but notably not for `==` nor `===`
  valueOf(): number {
    return this.toDate().getTime()
  }
}

type TSeason = 'Spring' | 'Summer' | 'Fall' | 'Winter'
type THemisphere = 'northern' | 'southern'

// Return the season from a date based on month, and invert it if in the southern hemisphere
export const getSeasonByHemisphere = (
  date: Date,
  hemisphere: THemisphere
): TSeason => {
  const month = date.getMonth() + 1
  if (month === 12 || month <= 2) {
    return hemisphere === 'northern' ? 'Winter' : 'Summer'
  } else if (month <= 5) {
    return hemisphere === 'northern' ? 'Spring' : 'Fall'
  } else if (month <= 8) {
    return hemisphere === 'northern' ? 'Summer' : 'Spring'
  } else {
    return hemisphere === 'northern' ? 'Fall' : 'Winter'
  }
}
