'use client'

import {safeUnreachableCase} from '~/utils/unreachableCase'
import {type ConstantFragment} from './_generated/fragments.graphql'
import {type Constant, useConstants} from './context'
import {truncateFromBigNumber} from '~/mercuryWebCompat/pages/Treasury/Onboarding/utils'
import dynamic from 'next/dynamic'

function RenderAPY(props: {apy: number}) {
  // Always use 2 decimal places, if you want to change this, you must go through compliance first
  // https://mercurytechnologies.slack.com/archives/C999HR36D/p1713309605247079
  return <>{truncateFromBigNumber(props.apy, 2)}%</>
}

const RenderDay = dynamic(async () => await import('./RenderDay'), {
  ssr: false,
})

type DatoConstantProps = {
  data: ConstantFragment
}

export function DatoConstant(props: DatoConstantProps) {
  const {data} = props
  const constants = useConstants()

  const typedKey = data.key as keyof Constant
  switch (typedKey) {
    case 'TREASURY_APY':
    case 'TREASURY_APY_AT_15_BPS_FEE_TIER':
    case 'TREASURY_APY_AT_25_BPS_FEE_TIER':
    case 'TREASURY_APY_AT_35_BPS_FEE_TIER':
    case 'TREASURY_APY_AT_45_BPS_FEE_TIER':
    case 'TREASURY_APY_AT_60_BPS_FEE_TIER':
    case 'PERSONAL_CHECKING_APY':
    case 'PERSONAL_SAVINGS_APY':
    case 'BUSINESS_CHECKING_APY':
    case 'BUSINESS_SAVINGS_APY':
      return <RenderAPY apy={constants[typedKey]} />
    case 'TREASURY_APY_UPDATED_AT':
    case 'PERSONAL_CHECKING_APY_UPDATED_AT':
    case 'PERSONAL_SAVINGS_APY_UPDATED_AT':
    case 'BUSINESS_CHECKING_APY_UPDATED_AT':
    case 'BUSINESS_SAVINGS_APY_UPDATED_AT':
      return <RenderDay day={constants[typedKey]} />
    default:
      return safeUnreachableCase(typedKey, null)
  }
}
