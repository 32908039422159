'use client'
import {createContext, useContext, useState} from 'react'
import {type SingleLineStructuredTextFragment} from '~/cms/components/SingleLineStructuredText/_generated/fragment.graphql'

type Disclaimer = {
  id: string
  text: SingleLineStructuredTextFragment['text']
  label: string
  kebabLabel: string
  number: number
}
type DisclaimerContext = {
  disclaimers: Record<string, Disclaimer>
  addDisclaimer: (disclaimer: Omit<Disclaimer, 'number'>) => void
}
const disclaimerContext = createContext<DisclaimerContext>({} as DisclaimerContext)

export function useDisclaimerContext() {
  const context = useContext(disclaimerContext)
  if (!context) {
    throw new Error('useDisclaimerContext must be used within a DisclaimerProvider')
  }
  return context
}

export default function DisclaimerProvider({children}: {children: React.ReactNode}) {
  const [disclaimers, setDisclaimers] = useState<DisclaimerContext['disclaimers']>(
    {}
  )

  function addDisclaimer(disclaimer: Omit<Disclaimer, 'number'>) {
    setDisclaimers(prev => {
      if (prev[disclaimer.id]) {
        return prev
      }
      return {
        ...prev,
        [disclaimer.id]: {
          ...disclaimer,
          number: Object.keys(prev).length + 1,
        },
      }
    })
  }

  return (
    <disclaimerContext.Provider value={{disclaimers, addDisclaimer}}>
      {children}
    </disclaimerContext.Provider>
  )
}
