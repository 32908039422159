// copied from https://github.com/MercuryTechnologies/mercury-web/blob/df2e9f889ab30e035476189dc396d366ebf43bdd/src/utils/Comparable/index.ts
export enum CompareResult {
  LT = -1,
  EQ = 0,
  GT = 1,
}

export type Comparable<T> = {
  // eslint-disable-next-line
  compare(other: Comparable<T>): CompareResult
}
export const greaterThanOrEqual = <T extends Comparable<T>>(a: T, b: T) => {
  const comparison = a.compare(b)
  return comparison === CompareResult.GT || comparison === CompareResult.EQ
}

export const greaterThan = <T extends Comparable<T>>(a: T, b: T) => {
  return a.compare(b) === CompareResult.GT
}

export const max = <T extends Comparable<T>>(a: T, b: T, ...additional: T[]): T => {
  return [a, b, ...additional].reduce((maximum, x) => {
    return greaterThan(x, maximum) ? x : maximum
  })
}

// Utility

export const compareArrays = (xs: number[], ys: number[]): CompareResult => {
  if (xs.length !== ys.length) {
    throw new Error(
      'compareArrays is intended to be used on arrays of the same length'
    )
  }
  for (let i = 0; i < xs.length; i++) {
    const x = xs[i]
    const y = ys[i]
    if (x < y) {
      return CompareResult.LT
    } else if (x > y) {
      return CompareResult.GT
    }
  }
  return CompareResult.EQ
}

export const contains = <T extends Comparable<T>>(elem: T, list: T[]): boolean => {
  for (const listItem of list) {
    if (elem.compare(listItem) === CompareResult.EQ) {
      return true
    }
  }
  return false
}
