// copied from https://github.com/MercuryTechnologies/mercury-web/blob/6cfdefbb616451b40927570bdb60c157a51315b4/src/utils/date.ts
// mercury-web has a bit more to set static date for functional testing, leaving that out for now
/**
 * A substitute for calling `new Date()`, to make it deterministic in testing.
 * Outside test, this will simply call `new Date()`. In a test environment, it will mock
 * the date to always be `2022-02-22T02:22:22`.
 *
 * For background for why this is needed, see:
 * https://linear.app/mercury/issue/FEDUX-229/use-deterministic-datestimes-in-storybook-tests
 *
 * @returns {Date} a new Date object, holding today's date.
 */
export const newDate = () => {
  return new Date()
}

/**
 * A substitute for calling `Date.now()`, to make it deterministic in testing.
 * Outside test, this will simply call `Date.now()`. In a test environment, it will return
 * `1645514542000` (which is the timestamp of the mock date we use, `2022-02-22T02:22:22`.)
 *
 * For background for why this is needed, see:
 * https://linear.app/mercury/issue/FEDUX-229/use-deterministic-datestimes-in-storybook-tests
 *
 * @returns {number} the number of milliseconds elapsed since the UNIX epoch.
 */
export const now = () => {
  return Date.now()
}
