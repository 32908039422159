'use client'
import {type PropsWithChildren, createContext, useContext} from 'react'

export type Constant = {
  TREASURY_APY: number
  TREASURY_APY_UPDATED_AT: Date

  TREASURY_APY_AT_15_BPS_FEE_TIER: number
  TREASURY_APY_AT_25_BPS_FEE_TIER: number
  TREASURY_APY_AT_35_BPS_FEE_TIER: number
  TREASURY_APY_AT_45_BPS_FEE_TIER: number
  TREASURY_APY_AT_60_BPS_FEE_TIER: number

  PERSONAL_CHECKING_APY: number
  PERSONAL_CHECKING_APY_UPDATED_AT: Date
  PERSONAL_SAVINGS_APY: number
  PERSONAL_SAVINGS_APY_UPDATED_AT: Date

  BUSINESS_CHECKING_APY: number
  BUSINESS_CHECKING_APY_UPDATED_AT: Date
  BUSINESS_SAVINGS_APY: number
  BUSINESS_SAVINGS_APY_UPDATED_AT: Date
}

export const constantsContext = createContext<null | Constant>(null)

export function useConstants() {
  const constants = useContext(constantsContext)
  if (!constants) {
    throw new Error('useConstants must be used within a ConstantsProvider')
  }
  return constants
}

export function ConstantsProvider(props: PropsWithChildren & {constants: Constant}) {
  const {children, constants} = props
  return (
    <constantsContext.Provider value={constants}>
      {children}
    </constantsContext.Provider>
  )
}
